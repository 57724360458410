import { getManageId } from '@/helpers';

export const API_URLS = {
  backendManage: {
    cities: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/cities`,
    },
    devices: {
      intercoms: {
        fetch: (manageId = getManageId()) =>
          `/api/backend/manage/${manageId}/autocomplete/intercoms/uuid_or_serial`,

        importByUuid: (manageId = getManageId()) =>
          `/api/backend/manage/${manageId}/intercoms/import_by_uuid_serial`,

        update: (manageId = getManageId(), intercomId) =>
          `/api/backend/manage/${manageId}/intercoms/${intercomId}`,

        validate: (manageId = getManageId(), intercomId) =>
          `/api/backend/manage/${manageId}/intercoms/${intercomId}/validate`,

        validate_params: (manageId = getManageId()) =>
          `/api/backend/manage/${manageId}/intercoms/validate_params`,
      },
      fa: {
        ppk: {
          fetch: (manageId = getManageId()) =>
            ` /api/backend/manage/${manageId}/autocomplete/fa_ppk_devices`,
        },
        can: {
          fetch: (manageId = getManageId()) =>
            `/api/backend/manage/${manageId}/autocomplete/fa_can_eth_devices`,
        },
      },
    },
    districts: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/main_districts`,
      one: (districtId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/main_districts/${districtId}`,
    },
    geoUnits: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/geo_units`,
      one: (geoUnitId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/geo_units/${geoUnitId}`,
      updateBuildingAddress: (geoUnitId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/geo_units/${geoUnitId}/update_building_address`,
    },
    nprGeoUnits: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/geo_units`,
      one: (geoUnitId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/geo_units/${geoUnitId}`,
    },
    camerasModels: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/cameras_models`,
    },
    cameras: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/cameras`,
    },
    cctv: {
      cameras: {
        devices: {
          fetch: (manageId = getManageId()) =>
            `/api/backend/manage/${manageId}/cctv/cameras/devices`,
        },
        device: {
          records: {
            days: (manageId = getManageId(), deviceId) =>
              `/api/backend/manage/${manageId}/cctv/cameras/devices/${deviceId}/records/days`,
            file: (manageId = getManageId(), deviceId, filename) =>
              `/api/backend/manage/${manageId}/cctv/cameras/devices/${deviceId}/records/video/${filename}`,
            playlist: (
              manageId = getManageId(),
              deviceId,
              { startTime, endTime },
            ) =>
              `/api/backend/manage/${manageId}/cctv/cameras/devices/${deviceId}/records/playlist.m3u?startTime=${startTime}&endTime=${endTime}`,
            timeline: (manageId = getManageId(), deviceId) =>
              `/api/backend/manage/${manageId}/cctv/cameras/devices/${deviceId}/records/timeline`,
          },
        },
      },
    },
    intercoms: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/intercoms`,
    },
    intercomModels: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/intercom_models`,
    },
    barrier: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/npr/barriers`,
      one: (barrierId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/npr/barriers/${barrierId}`,
    },
    barriers: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/barriers`,
    },
    plates: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/plates`,
    },
    events_plates: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/events_plates`,
    },
    users: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/autocomplete/users`,
    },
    metering: {
      geo_units: {
        fetch: (manageId = getManageId()) =>
          `/api/backend/manage/${manageId}/metering/geo_units`,
      },
    },
    keys: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/keys/`,
    },

    keysets: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/keysets`,
      one: (keySetId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/keysets/${keySetId}`,
    },
  },
  manage: {
    camera: {
      webRtcToken: (cameraId) => `/api/manage/cameras/${cameraId}/webrtc_token`,
    },
    intercom: {
      batchSetDisplayImage: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/intercoms/batch_set_display_image`,
      setDisplayImage: (intercomId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/intercoms/${intercomId}/set_display_image`,
      webRtcToken: (intercomId) =>
        `/api/manage/intercoms/${intercomId}/webrtc_token`,
    },
  },
  npr: {
    plates: {
      fetch: (manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/npr/plates/`,
      one: (plateId, manageId = getManageId()) =>
        `/api/backend/manage/${manageId}/npr/plates/${plateId}`,
    },
    cars: {
      fetch: () => `/api/backend/npr/autocomplete/cars`,
    },
    marks: {
      fetch: (manageId = getManageId()) =>
        ` /api/backend/manage/${manageId}/npr/car_marks`,
    },
    colors: {
      fetch: () => ` /api/backend/npr/plates/colors`,
    },
    types: {
      fetch: () => ` /api/backend/npr/plates/types`,
    },
  },
};
