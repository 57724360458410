import Vue from 'vue';
import Vuex from 'vuex';
import global from '@/store/global';
import intercom from '@/store/intercom';
import meters from '@/store/meters';
import barrier from '@/store/barrier';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    meters,
    global,
    intercom,
    barrier,
  },
});
