<template>
  <div>
    <slot
      :user-activities-with-section="userActivitiesWithSection"
      :user-sctivities-with-device-client-app="
        userActivitiesWithDeviceClientApp
      "
      :parse-date-time="parseDateTime"
      :human-number="humanNumber"
      :user-activities="userActivities"
    ></slot>
  </div>
</template>
<script>
import axios from 'axios';
import dayjs from 'dayjs';

export default {
  name: 'AdminUserShow',
  props: {
    userId: Number,
  },
  data() {
    return {
      userActivities: [],
    };
  },
  mounted() {
    this.getUserActivity();
  },
  methods: {
    userActivitiesWithSection(array) {
      return array.filter((obj) => obj['section'] && obj['section'].length > 0);
    },
    userActivitiesWithDeviceClientApp(array) {
      return array.filter((obj) =>
        ['Alfred', 'Rubetek'].includes(obj['device_client_app']),
      );
    },
    parseDateTime(datetime) {
      return dayjs.tz(datetime, 'UTC').fromNow();
    },
    humanNumber(number) {
      return new Intl.NumberFormat('ru-Ru').format(number);
    },
    async getUserActivity() {
      await axios
        .get(`/api/backend/admin/user_activity/${this.userId}`)
        .then((resp) => {
          this.userActivities = resp.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
