<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="portlet">
        <div class="h5 mt-1">
          {{ $t('global.device') }}
        </div>
        <div class="row pt-2">
          <div class="col-lg-6">
            <BSelectLabel
              v-model="device.area"
              :label="$t('meters.form.device_type')"
              :items="areas"
              objectProperty="id"
              display="name"
              @selected="areaChanged"
              :state="validateState('area')"
            />
          </div>
          <div class="col-lg-6">
            <BSelectLabel
              v-model="device.kind"
              :label="$t('meters.form.resource')"
              :items="data.kinds"
              display="name"
              objectProperty="id"
              @selected="kindChanged"
              :state="validateState('kind')"
            />
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-lg-6">
            <BSelectLabel
              v-model="device.meter_model_id"
              :label="$t('meters.form.model')"
              :items="models"
              display="name"
              objectProperty="id"
              @input:item="(val) => (device.model = val)"
              :state="validateState('meter_model_id')"
            />
          </div>
          <div class="col-lg-6">
            <BInputLabel
              v-model="device.serial"
              :label="$t('meters.form.serial')"
              :is-invalid="Boolean(errors['serial'])"
              :state="validateState('serial')"
            />
            <BError
              v-if="errors['serial'] && isSubmit"
              :error="$t('global.serial') + ' ' + errors['serial'].join(', ')"
            />
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-lg-6">
            <date-picker
              class="w-100 d-block"
              v-model="device.issued_on"
              :label="$t('meters.form.manufacture_date')"
              format="YYYY-MM-D HH:m:s"
              :is-invalid="Boolean(errors['issued_on'])"
            ></date-picker>
            <BError
              v-if="errors['issued_on'] && isSubmit"
              :error="
                $t('meters.form.manufacture_date') +
                ' ' +
                errors['issued_on'].join(', ')
              "
            />
          </div>
          <div class="col-lg-6">
            <date-picker
              class="w-100 d-block"
              v-model="device.installed_on"
              :label="$t('meters.form.installation_date')"
              format="YYYY-MM-D HH:m:s"
              :is-invalid="Boolean(errors['installed_on'])"
            ></date-picker>
            <BError
              v-if="errors['installed_on'] && isSubmit"
              :error="
                $t('meters.form.installation_date') +
                ' ' +
                errors['installed_on'].join(', ')
              "
            />
          </div>
        </div>
        <div v-if="showUnitedName" class="h5 mt-3">
          {{ unitedName }}
        </div>
        <div class="row pt-2">
          <div class="col-lg-6">
            <BInputLabel
              v-model="device.start_value"
              :label="$t('meters.form.reading_on_installation')"
            />
          </div>
          <div class="col-lg-6">
            <div v-if="photoUrlEmpty">
              <BInputFile
                accept="image/*"
                v-model="device.photo"
                :label="$t('meters.form.upload_device_photo')"
                type="file"
              ></BInputFile>
            </div>
            <div v-else>
              <img
                :src="device.photo_url"
                alt="photo"
                width="50px"
                height="50px"
              />
              <button
                @click="photoRemove"
                class="btn btn-sm btn-outline-primary"
              >
                {{ $t('meters.form.delete_photo') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="pipeIsVisible" class="row pt-2">
          <div class="col-lg-6">
            <BSelectLabel
              v-model="device.pipe_identifier"
              :label="$t('meters.form.choose_pipe')"
              :items="data.pipes"
              display="name"
              objectProperty="id"
            />
          </div>
        </div>
        <div v-if="!noArea" class="row pt-2">
          <div class="col-lg-6">
            <BFormCheckbox
              size="lg"
              class="creator-checkbox"
              v-model="device.is_calc"
            >
              {{ $t('meters.form.input_riser') }}
            </BFormCheckbox>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="portlet">
        <div class="h5 mt-1">
          {{ $t('global.location') }}
        </div>
        <div class="mt-3">
          <geo-units-autocomplete
            v-model="device.geo_unit_id"
            :geo-unit="device.geo_unit"
            :placeholder="$t('global.address')"
            :additional-query-params="{ type_in: this.addressSearchTypeIn }"
            :client-id="clientId"
            :disabled="noArea"
            @select="geoUnitChanged"
            @select:item="(val) => (device.geo_unit = val)"
            @clear="device.geo_unit = null"
          ></geo-units-autocomplete>
        </div>
      </div>
      <div v-if="showUspdConnection" class="portlet">
        <div class="h5 mt-1">
          {{ $t('meters.form.binding_to_USPD') }}
        </div>
        <div class="mt-3">
          <uspd-devices-autocomplete
            v-model="device.uspd_device_id"
            :placeholder="$t('meters.form.serial_USPD')"
            ref="uspd_device"
            objectProperty="id"
            :client-id="clientId"
            :geo-unit-id="device.geo_unit_id"
            :uspd="device.uspd_device"
            :disabled="uspdDisabled"
            @select:item="(val) => (device.uspd_device = val)"
            @clear="device.uspd_device = null"
          ></uspd-devices-autocomplete>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BError from '@/components/base/BError';
import BInputFile from '@/components/base/BInputFile';
import BInputLabel from '@/components/base/BInputLabel';
import BSelectInputLabel from '@/components/base/BSelectInputLabel';
import DatePicker from '@app/components/base/DatePicker';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import UspdDevicesAutocomplete from '@/components/autocompletes/uspdDevicesAutocomplete';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'meteringMeterBaseForm',
  components: {
    BError,
    BInputLabel,
    BInputFile,
    BSelectInputLabel,
    DatePicker,
    GeoUnitsAutocomplete,
    UspdDevicesAutocomplete,
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    currentArea: null,
    data: {
      type: Object,
    },
    errors: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    validateState: {
      type: Function,
    },
    showUspdConnection: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      models: [],
    };
  },
  computed: {
    ...mapState('meters', ['device']),
    ...mapGetters('meters', ['getDevice']),
    device: {
      get() {
        return this.getDevice;
      },
      set(value) {
        this.setDevice(value);
      },
    },
    pipeIsVisible() {
      if (this.noArea) {
        return false;
      } else {
        if (this.device.kind == 'electro') {
          return false;
        }
      }
      return true;
    },
    addressSearchTypeIn() {
      if (this.noArea) return;

      let types = ['Apartment', 'Bkfn', 'TechRoom', 'ParkingPlace'];
      if (this.device.area === 'chmd') types = ['Building'];
      return types;
    },
    kindHumanState() {
      if (!this.device.kind) return '';

      let kind_united = {
        state: 'water',
        human: this.$t('meters.form.of_water'),
      };
      switch (this.device.kind) {
        case 'heat':
          kind_united = {
            state: 'heat',
            human: this.$t('meters.form.of_heat'),
          };
          break;
        case 'electro':
          kind_united = {
            state: 'electro',
            human: this.$t('meters.form.of_electricity'),
          };
          break;
      }
      return kind_united;
    },
    modelHuman() {
      if (this.device.model && this.device.model.name) {
        return this.device.model.name;
      } else return null;
    },
    noArea() {
      return !this.device.area;
    },
    showUnitedName() {
      return this.device.kind || this.device.meter_model_id;
    },
    unitedName() {
      return [
        this.$t('meters.meter'),
        this.kindHumanState.human,
        this.modelHuman,
      ].join(' ');
    },
    waterCounter() {
      return this.kindHumanState.state === 'water';
    },
    uspdDisabled() {
      if (this.device.geo_unit_id != null) {
        return false;
      } else return true;
    },
    photoUrlEmpty() {
      return this.device.photo_url === null || this.device.photo_url === ''
    },
  },
  async created() {
    await this.modelsSearch();
    if (!this.editMode && this.data.models) {
      this.models = this.data.models;
    }
  },
  methods: {
    ...mapMutations('meters', ['setDevice']),

    async areaChanged() {
      await this.modelsSearch();
      this.device = Object.assign({}, this.device, {
        geo_unit: null,
        geo_unit_id: null,
        uspd_device_id: null,
        uspd_device: null,
        model: null,
        meter_model_id: null,
        installed_on: null,
        issued_on: null,
      });
    },
    geoUnitChanged() {
      this.uspdDeviceIdReset();
    },
    async kindChanged() {
      this.device = Object.assign({}, this.device, {
        model: null,
        meter_model_id: null,
      });
      await this.modelsSearch();
      this.pipeIdentifierSet();
    },
    async modelsSearch() {
      if (this.device.area === 'pulse') return;

      let prms = {
        q: {
          meter_resource: this.kindHumanState.state,
          area: this.device.area,
        },
      };
      this.models = await request2(
        {
          method: 'get',
          url: `/api/backend/manage/${this.clientId}/meter_models`,
        },
        prms,
      ).then((r) => {
        if (this.device.meter_model_id) {
          this.device.model = r.models.filter(
            (x) => x.id === this.device.meter_model_id,
          )[0];
        }
        return r.models;
      });
    },
    photoRemove() {
      this.device.photo = null;
      this.device.photo_url = null;
      this.device.remove_photo = true;
    },
    pipeIdentifierSet() {
      if (this.waterCounter) {
        this.device.pipe_identifier = 1;
      } else {
        this.device.pipe_identifier = null;
      }
    },
    uspdDeviceIdReset() {
      if (this.device.uspd_device) {
        this.device = Object.assign({}, this.device, {
          uspd_device: null,
          uspd_device_id: null,
        });
      }
    },
  },
};
</script>
