<template>
  <div>
    <b-modal id="intercom-key-modal" ref="modal" @ok="onOk">
      <template #modal-header="{ close }">
        <div>
          <div class="title">
            {{
              model.id
                ? $t('intercom.intercom_keys.add.edit_title')
                : $t('intercom.intercom_keys.add.create_title')
            }}
          </div>
          <button class="close" @click="close()">×</button>
        </div>
      </template>
      <b-form-validate ref="form" :model="model" :validations="validations">
        <template slot-scope="{ validateState }">
          <div class="modal-body-row with-cols border-bottom">
            <div class="title">{{ $t('intercom.key.data') }}</div>
            <b-form-row v-if="model.id">
              <b-col md="6">
                <BInputLabel
                  id="input-key-modal_id"
                  :label="$t('intercom.key.id')"
                  disabled
                  v-model="model.id"
                />
              </b-col>
              <b-col md="6">
                <BInputLabel
                  disabled
                  id="input-key-modal_created"
                  :label="$t('intercom.key.created')"
                  v-model="getCreatedAt"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="6">
                <BInputLabel
                  id="input-key-modal_number"
                  :label="$t('intercom.key.number')"
                  :state="validateState('number')"
                  v-model="model.number"
                />
              </b-col>
              <b-col md="6">
                <BInputLabel
                  id="input-key-modal_code"
                  :label="$t('intercom.key.code')"
                  :state="validateState('code')"
                  v-model="model.code"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="6">
                <SelectKeySet
                  id="select-key-modal_set"
                  :label="$t('intercom.key.set')"
                  v-model="model.keyset_id"
                  :state="validateState('keyset_id')"
                  :clientId="clientId"
                />
              </b-col>
              <b-col md="6">
                <BSelectLabel
                  id="intercom-key-type"
                  :items="keyTypes"
                  :label="$t('intercom.key.type')"
                  :state="validateState('type')"
                  display="label"
                  v-model="model.type"
                  object-property="type"
                  no-padding-label
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="6">
                <BSelectSearch
                  id="input-key-modal_user"
                  :handler="searchUser"
                  :format-result="formatSearchResult"
                  :label="$t('intercom.intercom_keys.add.user_search_title')"
                  @select:item="(item) => (model.user = item)"
                  v-model="model.user.id"
                  :selected="
                    model.user || {
                      id: null,
                    }
                  "
                  :displayProperty="displayUser"
                  @clear="clearUser"
                />
              </b-col>
              <b-col md="6">
                <BInputLabel
                  id="input-number"
                  :label="$t('intercom.key.name')"
                  v-model="model.name"
                />
              </b-col>
            </b-form-row>
          </div>
        </template>
      </b-form-validate>
      <div class="modal-body-row with-cols">
        <div class="title">
          {{ $t('intercom.intercom_keys.add.access_title') }}
        </div>
        <b-form-row>
          <b-col cols="12">
            <div
              v-for="(relation, index) in visibleAccessGu"
              :key="`${relation.id}_${index}`"
              class="d-flex"
            >
              <GeoUnitsAutocomplete
                v-model="model.related_geo_units[relation.index].id"
                :client-id="Number(clientId)"
                :geo-unit.sync="model.related_geo_units[relation.index]"
                :placeholder="$t('address.title')"
                geo-unit-property="post_name"
                section="intercom"
                class="flex-grow-1 mb-3"
                :additional-query-params="{
                  type_not_in: ['City', 'Street'],
                }"
                @select:item="(gu) => selectGu(gu, relation.index)"
                @clear="model.related_geo_units[relation.index] = { id: null }"
              />
              <b-button
                @click="onRemoveGeoUnit(relation.index)"
                class="btn-square"
                variant="outline-primary"
              >
                <i class="icon-local-trash"></i>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-button variant="info" block @click="onAddRelatedGeoUnit">
                {{ $t('button.add_property') }}
              </b-button>
            </b-form-group>
          </b-col>
        </b-form-row>
        <template v-if="model.type === 'Service'">
          <div class="title">{{ $t('district.title') }}</div>
          <b-form-row>
            <b-col v-if="isShowDistrictAutocomplete" cols="12">
              <div class="d-flex">
                <DistrictsAutocomplete
                  v-model="model.district_id"
                  @clear="onClearDistrict"
                  @select:item="onSelectDistrict"
                  class="flex-grow-1 mb-3"
                  displayProperty="name"
                />
                <b-button
                  @click="onRemoveDistrict"
                  class="btn-square"
                  variant="outline-primary"
                >
                  <i class="icon-local-trash"></i>
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">
              <b-form-group v-if="!isShowDistrictAutocomplete">
                <b-button variant="info" block @click="onAddDistrict">
                  {{ $t('button.add_district') }}
                </b-button>
              </b-form-group>
              <b-form-group>
                <b-button
                  variant="outline-primary"
                  block
                  :disabled="isDisabledSetupDoorAccessBtn"
                  @click="onSetupDoorsAccess"
                >
                  {{ $t('button.setup_doors_access') }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-form-row>
        </template>
        <div class="intercom-key-hint">
          {{ $t('intercom.intercom_keys.add.hint') }}
        </div>
      </div>
    </b-modal>
    <RelaysGroupsSettingModal
      v-model="model.keys_relays_black_lists"
      :district="model.district"
      :related-geo-units="model.related_geo_units"
      ref="SetupAccessModal"
    />
  </div>
</template>

<script>
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import { ApiKeys, fetchDistricts } from '@/api';
import DistrictsAutocomplete from '@/components/autocompletes/DistrictsAutocomplete';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import BSelectSearch from '@/components/base/BSelectSearch.vue';
import SelectKeySet from '@/components/intercom/SelectKeySet.vue';
import RelaysGroupsSettingModal from '@/components/modals/RelaysGroupsSettingModal.vue';
import EventBus from '@/packs/EventBus';
import _, { omit } from 'lodash';
import dayjs from 'dayjs';
import Vue from 'vue';
import { numeric, required, maxLength } from 'vuelidate/lib/validators';

function initData(key) {
  return {
    model: (key?.id &&
      _.cloneDeep({
        ...key,
        district_id: key.district?.id || null,
        user: key.user?.id ? key.user : { id: null },
      })) || {
      district_id: null,
      id: '',
      name: '',
      number: '',
      code: '',
      keyset_id: null,
      user: {
        id: null,
      },
      type: null,
      related_geo_units: [],
      keys_relays_black_lists: [],
    },
    originBlackList: (key?.keys_relays_black_lists || []).map((relay) => relay),
    isCreate: !key,
    owner: {},
  };
}

export default Vue.extend({
  name: 'KeyModal',
  components: {
    RelaysGroupsSettingModal,
    SelectKeySet,
    BSelectSearch,
    GeoUnitsAutocomplete,
    DistrictsAutocomplete,
  },
  props: {
    clientId: {},
  },
  data() {
    return {
      ...initData(),
      intercoms: [],
      isShowDistrictAutocomplete: false,
      findUsers: [],
      originBlackList: [],
    };
  },
  computed: {
    getCreatedAt() {
      return (
        this.model.created_at && dayjs(this.model.created_at).format('LLL')
      );
    },
    formatSearchResult() {
      return {
        top: [
          {
            title: 'name',
            formatter: (val, item) =>
              `${item.surname || ''} ${item.name || ''} ${
                item.patronymic || ''
              }`,
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) =>
              [item.phone, item.email].filter((e) => e).join(', '),
          },
        ],
      };
    },
    keyTypes() {
      return [
        {
          label: this.$t('intercom.intercom_keys.add.service'),
          type: 'Service',
        },
        { label: this.$t('intercom.intercom_keys.add.common'), type: 'Common' },
      ];
    },
    isDisabledSetupDoorAccessBtn() {
      if (this.model.district_id) return false;
      return !this.model.related_geo_units.find(({ id }) => id);
    },
    validations() {
      return {
        code: {
          required,
          maxLength: maxLength(14),
          isHexadecimal: (value) => {
            const regex = /^[0-9a-fA-F]+$/gm;
            return Boolean(regex.exec(value));
          },
        },
        number: { required },
        keyset_id: { required, numeric },
        type: { required },
      };
    },
    visibleAccessGu() {
      const list = this.model.related_geo_units;
      for (let i = 0; i < list.length; i++) {
        list[i].index = i;
      }

      return list.filter((relation) => relation.user_has_access == true);
    },
  },
  watch: {
    'model.type': 'onTypeChange',
  },
  mounted() {
    EventBus.$on('KeyModal::show', this.show);
  },
  methods: {
    async show(key) {
      Object.assign(this.$data, initData(key));
      this.isShowDistrictAutocomplete =
        this.model.type === 'Service' && this.model.district;
      if (key && this.model.related_geo_units_ids) {
        this.getGeoUnitUsers();
      }
      this.$refs.modal.show();
    },
    onTypeChange(val) {
      if (val !== 'Service') {
        this.model.keys_relays_black_lists = [];
        this.model.district_id = null;
      }
    },
    searchUser(val) {
      const encoldeVal = encodeURIComponent(val);
      return request3
        .get(API_URLS.backendManage.users.fetch(this.clientId), {
          params: {
            q: {
              phone_or_email_or_name_or_surname_or_patronymic_or_full_name_or_reverse_full_name_or_users_clients_name_or_users_clients_surname_or_users_clients_patronymic_or_users_clients_full_name_or_users_clients_reverse_full_name_cont_any:
                encoldeVal,
            },
            per_page: 10,
          },
        })
        .then((res) => res.data);
    },
    clearUser() {
      this.model.user = {
        id: null,
      };
    },
    onAddRelatedGeoUnit() {
      this.model.related_geo_units.push({
        user_has_access: true,
      });
    },
    onAddDistrict() {
      this.isShowDistrictAutocomplete = true;
    },
    onRemoveGeoUnit(index) {
      this.model.related_geo_units.splice(index, 1);
    },
    onRemoveDistrict() {
      this.isShowDistrictAutocomplete = false;
      this.model.district_id = null;
    },
    onClearDistrict() {
      this.model.district_id = null;
      this.model.district = null;
    },
    onSelectDistrict(district) {
      this.model.district_id = district.id;
      this.model.district = district;
    },
    async onSetupDoorsAccess() {
      const district = await fetchDistricts(this.model.district_id);
      this.$set(this.model.district, 'buildings', district.buildings);
      this.$set(this.model.district, 'territories', district.territories);
      this.$refs.SetupAccessModal.show();
    },
    async onOk(event) {
      if (Object.prototype.hasOwnProperty.call(event, 'preventDefault')) {
        event.preventDefault();
      }
      if (this.$refs.form.validate()) {
        // Select mode
        const mode = this.model.id ? 'update' : 'create';
        // Show preloader
        EventBus.$emit(
          'preloader::show',
          mode === 'create'
            ? this.$t('intercom.intercom_keys.add.creating_key')
            : this.$t('intercom.intercom_keys.add.updating_key'),
        );

        const { keys_relays_black_lists, related_geo_units, ...model } =
          this.model;

        const blackList = [];
        keys_relays_black_lists.forEach((relay) => {
          const existOriginBlackList = this.originBlackList.find(
            (origin) => origin.relay_id === relay.relay_id,
          );
          if (!existOriginBlackList) {
            blackList.push(relay);
          }
        });

        this.originBlackList.forEach((origin) => {
          const hasNotInOriginBlackList = keys_relays_black_lists.every(
            (relay) => relay.relay_id !== origin.relay_id,
          );
          if (hasNotInOriginBlackList) {
            blackList.push({ ...origin, _destroy: true });
          }
        });

        const key = {
          ...omit(model, ['district', 'issuer', 'user']),
          keys_relays_black_lists_attributes: blackList,
          related_geo_units_ids: related_geo_units.map(({ id }) => id),
          user_id: this.model.user?.id ? this.model.user.id : null,
        };
        await ApiKeys[mode](key)
          .then(() => {
            EventBus.$emit('Key::refresh');
            if (mode === 'update') EventBus.$emit('Key::update');
            this.$refs.modal.hide();
          })
          .catch((err) => {
            if (err.errors instanceof Object) {
              const [[keyField, errorMessage]] = Object.entries(err.errors);
              const keyFieldTranslated = this.$t(`intercom.key.${keyField}`);
              this.$bvToast.toast(`${keyFieldTranslated}: ${errorMessage}`);
            } else {
              this.$bvToast.toast(err);
            }
          });

        EventBus.$emit('preloader::hide');
      }
    },
    displayUser(val) {
      if (val.name || val.surname || val.patronymic) {
        return `${val.surname || ''} ${val.name || ''} ${val.patronymic || ''}`;
      }
      return val.phone;
    },
    selectGu(geoUnit, index) {
      this.model.related_geo_units[index] = Object.assign(
        { user_has_access: true },
        geoUnit,
      );
    },
  },
});
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

#intercom-key-modal_ {
  .btn-info {
    height: 50px !important;
    color: #03a9f4;
  }
}
.intercom-key-hint {
  margin-bottom: 10px;
  color: $gray-600;
  font-style: italic;
}
</style>
