<template>
  <BDropdownMenu
    v-if="items.length > 0"
    id="nav-bar-button-add"
    :items="items"
    position="right"
    ref="dropdown"
  />
</template>

<script>
import BDropdownMenu from '@/components/base/BDropdownMenu.vue';
import { EVENTS } from '@/consts';
import EventBus from '@/packs/EventBus';

const rolesMap = {
  geo_units: [
    'Admin',
    'Owner',
    'IntercomManager',
    'MeteringManager',
    'SaManager',
    'FaManager',
    'CctvManager',
  ],
  intercoms: ['Admin', 'Owner', 'IntercomManager', 'NprManager'],
  cctv: ['Admin', 'Owner', 'CctvManager', 'NprManager'],
  metering: ['Admin', 'Owner', 'MeteringManager'],
  fa: ['Admin', 'Owner', 'FaManager'],
  sa: ['Admin', 'Owner', 'SaManager'],
  parking: ['Admin', 'Owner', 'NprManager'],
};
export default {
  name: 'NavBarButtonAdd',
  components: { BDropdownMenu },
  props: {
    clientId: {
      required: true,
    },
    currentPermissions: {
      required: true,
    },
    clientAclBuildingsIds: {
      required: true,
    },
    isCctvEnabled: {
      required: true,
    },
  },
  data() {
    return {
      items: this.filterdByRolesMenu(),
    };
  },
  methods: {
    filterdByRolesMenu() {
      const permissions = JSON.parse(this.currentPermissions).filter(
        (r) => !r.includes('Viewer'),
      );
      const client_acl_buildings_ids = JSON.parse(this.clientAclBuildingsIds);
      const is_cctv_enabled = this.isCctvEnabled === 'true';
      const menu = this.fullMenu();
      menu.forEach(function (menuItem) {
        menuItem.child = menuItem.child.filter((child) => {
          if (child.section === 'geo_units') {
            return (
              rolesMap[child.section].filter((x) => permissions.includes(x))
                .length > 0 &&
              (permissions.includes('Owner') ||
                Object.keys(client_acl_buildings_ids).length === 0)
            );
          }
          if (child.section !== 'cctv') {
            return (
              rolesMap[child.section].filter((x) => permissions.includes(x))
                .length > 0
            );
          }
          return (
            rolesMap[child.section].filter((x) => permissions.includes(x))
              .length > 0 && is_cctv_enabled
          );
        });
      });
      return menu.filter((menuItem) => menuItem.child.length > 0);
    },
    fullMenu() {
      return [
        {
          title: this.$t('global.device'),
          icon: 'icon-local-heat-meter',
          child: [
            {
              title: this.$t('intercom.title'),
              section: 'intercoms',
              handler: () => {
                EventBus.$emit(EVENTS.intercom.fast.modal.show, 'call_panel');
              },
            },
            {
              title: this.$t('add_button.controller'),
              section: 'intercoms',
              handler: () => {
                EventBus.$emit(
                  EVENTS.intercom.fast.modal.show,
                  'access_control',
                );
              },
            },
            {
              title: this.$t('intercom.uspd.title'),
              section: 'metering',
              disabled:
                location.pathname ===
                `/manage/${this.clientId}/metering/uspd_devices/new`,
              handler: () => {
                location.pathname = `/manage/${this.clientId}/metering/uspd_devices/new`;
              },
            },
            {
              title: this.$t('add_button.fire_system_fa'),
              section: 'fa',
              disabled:
                location.pathname ===
                `/manage/${this.clientId}/fa/can_eth_devices/new`,
              handler: () => {
                location.pathname = `/manage/${this.clientId}/fa/can_eth_devices/new`;
              },
            },
            {
              title: this.$t('add_button.fire_system_sa'),
              section: 'sa',
              disabled:
                location.pathname ===
                `/manage/${this.clientId}/sa/can_eth_devices/new`,
              handler: () => {
                location.pathname = `/manage/${this.clientId}/sa/can_eth_devices/new`;
              },
            },
            {
              title: this.$t('add_button.camera'),
              section: 'cctv',
              disabled:
                location.pathname ===
                `/manage/${this.clientId}/cctv/cameras/new`,
              handler: () => {
                location.pathname = `/manage/${this.clientId}/cctv/cameras/new`;
              },
            },
          ],
        },
        {
          title: this.$t('add_button.geo_unit'),
          icon: 'icon-local-city',
          section: 'geo_units',
          child: [
            {
              title: this.$t('add_button.building'),
              section: 'geo_units',
              disabled:
                location.pathname === `/manage/${this.clientId}/districts/new`,
              handler: () => {
                // this.$refs.dropdown.$children[0].hide();
                // this.$router.push({
                //   name: 'districts-building-create',
                // });
                location.pathname = `/manage/${this.clientId}/districts/new`;
              },
            },
            {
              title: this.$t('add_button.district'),
              section: 'geo_units',
              handler: () => {
                EventBus.$emit('createDistrictModal::show');
              },
            },
          ],
        },
        {
          title: this.$t('add_button.key'),
          icon: 'icon-local-key-card',
          child: [
            {
              title: this.$t('add_button.key'),
              section: 'intercoms',
              handler: () => {
                EventBus.$emit('KeyModal::show');
              },
            },
            {
              title: this.$t('add_button.key_set'),
              section: 'intercoms',
              handler: () => {
                EventBus.$emit('KeySetModal::show');
              },
            },
            {
              title: this.$t('add_button.keys_upload_from_file'),
              section: 'intercoms',
              handler: () => {
                EventBus.$emit('KeysImportFileModal::show');
              },
            },
          ],
        },
        {
          title: this.$t('add_button.parking'),
          icon: 'icon-local-parkingarea',
          child: [
            {
              title: this.$t('add_button.plate'),
              section: 'parking',
              handler: () => {
                EventBus.$emit('PlateModal::show');
              },
            },
            {
              title: this.$t('add_button.waypoint'),
              section: 'parking',
              disabled:
                location.pathname ===
                `/manage/${this.clientId}/npr/barriers/new`,
              handler: () => {
                location.pathname = `/manage/${this.clientId}/npr/barriers/new`;
              },
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss">
#nav-bar-button-add {
  ul.dropdown-menu {
    top: 50px !important;
  }
  .b-dropdown-menu-child {
    .b-dropdown-menu-child-item {
      width: 200px;

      .dropdown-item-text {
        white-space: normal;
      }
    }
  }
}
</style>
