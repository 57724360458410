<template>
  <div>
    <div class="d-flex flex-grow-1">
      <div class="d-flex flex-grow-1 flex-column">
        <cameras-autocomplete
          :placeholder="$t('barrier.placeholder_autocomplete')"
          :clientId="clientId"
          :cameraData="camera"
          :selectedCameras="camerasIds"
          @clear="onClear(index)"
          @select:item="(value) => setCamera({ index: index, camera: value })"
          class="flex-grow-1"
          :state="cameraState('uuid')"
        />
        <b-error
          v-if="camerasErrors.$each.$iter[index]['uuid'].$invalid && isSubmit"
          :error="$t('global.errors.not_empty')"
        />
      </div>
      <b-button
        variant="outline-danger"
        class="btn-square btn-trash cameras-list__btn"
        :disabled="disabledRemove"
        @click="onRemoveItem(index)"
      >
        <i class="icon-local-trash" />
      </b-button>
    </div>
    <div class="d-flex justify-content-between align-items-start mt-3">
      <b-select-label
        :label="$t('camera.npr_direction')"
        :items="drivingDirections"
        display="title"
        object-property="value"
        v-model="camera.npr_direction"
        @select="setCamera({ index: index, camera: camera })"
        class="w-50 mr-3"
        :state="cameraState('npr_direction')"
      />
      <b-button
        class="cameras-list__btn btn-info w-50"
        @click="openSelectZone(camera)"
        :disabled="!camera.id"
      >
        {{ $t('camera.npr_zone') }}
      </b-button>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <b-select-label
        :label="$t('camera.relay_id')"
        :items="validatedRelays"
        display="title"
        object-property="id"
        v-model="camera.relay_id"
        :disabled="disabledRelaySelect"
        class="w-50 mr-3"
        @select="setCamera({ index: index, camera: camera })"
        :state="cameraState('relay_id')"
      />
      <div class="w-50 d-flex align-items-center">
        <b-input-hint-label
          :value="camera.attempt_time"
          :placeholder="$t('camera.attempt_time.title')"
          popover-text="i"
          :popover-hint="$t('camera.attempt_time.hint')"
          :disabled="!camera.id"
          :hint-id="`attemptTimeHint${index}`"
          class="flex-grow-1"
          @update:value="(value) => attemptTimeUpdate(value, camera, index)"
          :ref="`attemptTimeInput${index}`"
          :error="
            isIvalidAttemptTime
              ? $t('camera.attempt_time.error_validate')
              : null
          "
          :state="isIvalidAttemptTime ? false : undefined"
          :key="key"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BInputHintLabel from '@/components/base/BInputHintLabel';
import CamerasAutocomplete from '@/components/autocompletes/CamerasAutocomplete';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import BError from '@/components/base/BError';

export default {
  name: 'Camera',
  components: { BInputHintLabel, CamerasAutocomplete, BError },
  props: {
    index: {
      type: Number,
      required: true,
    },
    drivingDirections: {
      type: Array,
      default() {
        return [];
      },
    },
    validatedRelays: {
      type: Array,
      default() {
        return [];
      },
    },
    clientId: {
      type: [Number, String],
    },
    camerasIds: {
      type: Array,
      default() {
        return [];
      },
    },
    disabledRemove: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    camerasErrors: {},
  },
  data() {
    return {
      key: Date.now(),
    };
  },
  computed: {
    ...mapState('barrier', ['barrier']),
    ...mapGetters('barrier', ['getCamera']),

    camera: {
      get() {
        return this.getCamera(this.index);
      },
      set(value) {
        this.setCamera({ index: this.index, camera: value });
      },
    },

    disabledRelaySelect() {
      return this.validatedRelays.length == 0;
    },
    isIvalidAttemptTime() {
      return (
        this.camera.attempt_time > 180 ||
        !this.camera.attempt_time ||
        this.camera.attempt_time == 0
      );
    },
    state() {
      if (this.isSubmit) {
        return this.isIvalidAttemptTime ? false : true;
      }
      return this.isIvalidAttemptTime ? false : undefined;
    },
  },
  methods: {
    ...mapMutations('barrier', [
      'onClearCamera',
      'onRemoveCamera',
      'setCamera',
    ]),

    cameraState(field) {
      if (!this.errors || !this.isSubmit) {
        return undefined;
      }
      return !this.camerasErrors.$each.$iter[this.index][field].$invalid;
    },

    onClear(index) {
      this.onClearCamera(index);
    },

    onRemoveItem(index) {
      this.onRemoveCamera(index);
    },

    openSelectZone() {
      this.$emit('selectZone', this.camera);
    },

    attemptTimeUpdate(value, camera, index) {
      let device = camera;
      value = value.replace(/\D/g, '');
      value = Number(value);
      device.attempt_time = value;
      this.setCamera({ index: index, camera: Object.assign({}, device) });
      this.camera = Object.assign({}, device);
      this.key = Date.now();
      this.$nextTick(() => {
        this.$refs['attemptTimeInput' + index].onFocus();
      });
    },
  },
};
</script>

<style></style>
