<template>
  <div>
    <b-modal
      id="intercom-fast-modal"
      class="fast-modal"
      ref="uuidSelect"
      :title="$t('devices.modal.create')"
      size="sm"
      no-stacking
    >
      <div class="modal-body-row with-cols pt-30">
        <b-form-row>
          <b-col cols="12">
            <DevicesAutocomplete
              :clientId="clientId"
              v-model="intercom.uuid_or_serial"
              @select:item="selectDevice"
              :placeholder="$t('devices.modal.uuid_or_serial')"
            />
            <span id="intercom-uuid-info" class="fast-modal__hint">
              {{ $t('devices.modal.uuid_hint') }}
            </span>
            <b-popover
              target="intercom-uuid-info"
              triggers="hover"
              placement="bottom"
            >
              <p>
                {{ $t('devices.modal.hint_text') }}
                <a :href="platform_support">
                  {{ $t('devices.modal.hint_link') }}
                </a>
              </p>
            </b-popover>
          </b-col>
          <b-button
            @click="openIntercomSelectionModal"
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto mx-auto"
          >
            {{ $t('devices.modal.model_selection') }}
          </b-button>
        </b-form-row>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
            @click="hide"
          >
            {{ $t('devices.modal.cancel') }}
          </b-button>
          <b-button
            @click="openReadSelectedModal"
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            :disabled="!intercom.uuid"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <SelectIntercomModelModal
      ref="SelectIntercomModel"
      id="intercom-select-model"
      :clientId="clientId"
      :type="type"
    />

    <ReadSelectedModal
      :clientId="clientId"
      ref="ReadSelectedModal"
      id="intercom-read-selected"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ReadSelectedModal from '../fastAdd/ReadSelectedModal.vue';
import DevicesAutocomplete from '@/components/autocompletes/DevicesAutocomplete.vue';
import SelectIntercomModelModal from '../modelSelection/SelectIntercomModelModal.vue';

export default {
  name: 'UuidSelectModal',
  components: {
    ReadSelectedModal,
    DevicesAutocomplete,
    SelectIntercomModelModal,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('intercom', ['intercom']),
    ...mapGetters('intercom', ['getIntercom', 'getType']),
    ...mapState('global', ['global']),
    ...mapGetters('global', ['getPlatformSupport']),

    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    type: {
      get() {
        return this.getType;
      },
      set(value) {
        this.setType(value);
      },
    },
    platform_support() {
      return this.getPlatformSupport;
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom', 'resetIntercom', 'setType']),

    show(type, reset = true) {
      if (type) {
        this.type = type;
      }
      if (reset) {
        this.resetIntercom();
      }
      this.$refs.uuidSelect.show();
    },
    hide() {
      this.$refs.uuidSelect.hide();
    },
    selectDevice(device) {
      this.setIntercom(
        Object.assign({}, device, {
          uuid_or_serial:
            device.matched_field == 'uuid' ? device.uuid : device.serial,
        }),
      );
    },
    openIntercomSelectionModal() {
      this.$refs.SelectIntercomModel.show();
    },
    openReadSelectedModal() {
      this.$refs.ReadSelectedModal.show();
    },
  },
};
</script>

<style></style>
