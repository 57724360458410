<template>
  <div class="calls-tab">
    <b-form-row v-if="isRequiredSelectSipServer">
      <b-col md="12" class="mb-3">
        <b-select-label
          id="select-intercom-sip_server_id"
          :items="actualServers"
          :custom-display="intercomSipServer"
          object-property="id"
          :label="$t('devices.tabs.calls.sip_server')"
          v-model="intercom.sip_server_id"
          zero-val-active
          class="my-0"
          :state="validateState ? validateState('sip_server_id') : undefined"
        />
        <b-rrror
          v-if="!sipErrors.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <div v-if="isShowAnalog || isShowProxyCall">
      <b-form-row>
        <b-col>
          <b-form-group>
            <b-form-radio
              v-model="callMode"
              :value="CALL_MODE_TYPES.regular"
              name="call_mode"
              @change="inputMode"
            >
              {{ $t('devices.tabs.calls.regular') }}
            </b-form-radio>
            <b-form-radio
              v-if="isShowAnalog"
              v-model="callMode"
              :value="CALL_MODE_TYPES.analog"
              name="call_mode"
              @change="inputMode"
            >
              {{ $t('devices.tabs.calls.analog') }}
            </b-form-radio>
            <b-form-radio
              v-if="isShowProxyCall"
              v-model="callMode"
              :value="CALL_MODE_TYPES.proxy"
              name="call_mode"
              @change="inputMode"
            >
              {{ $t('devices.tabs.calls.use_redirection_scheme.title') }}
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="isShowRelative">
        <b-col md="12">
          <b-form-group class="m-0">
            <b-form-checkbox v-model.number="intercom.settings.is_relative">
              {{ $t('devices.tabs.calls.use_relative_number.title') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row
        v-if="intercom.settings.is_analog && coordSchemaAvailable"
        class="align-items-center mt-3"
      >
        <b-col md="12">
          <b-input-hint-label
            id="input-intercom-modal_coord-schema"
            name="intercom-coord-schema"
            :value="intercom.settings.coord_schema"
            :placeholder="$t('devices.tabs.calls.range.title')"
            popover-text="i"
            :popover-hint="$t('devices.tabs.calls.range.hint')"
            hint-id="coord_schema"
            class="flex-grow-1"
            @update:value="(value) => (intercom.settings.coord_schema = value)"
          />
        </b-col>
      </b-form-row>
      <b-form-row
        class="mt-3"
        v-if="intercom.settings.is_analog && selectedOptions.type"
      >
        <b-col md="6">
          <b-select-label
            :value="selectedOptions.type"
            :items="TYPE_ITEMS"
            display="title"
            object-property="value"
            :label="$t('devices.tabs.calls.order')"
            @input="onInputType"
          ></b-select-label>
        </b-col>
        <b-col md="6" v-if="selectedOptions.type !== 'both'">
          <b-select-label
            :value="selectedOptions.delay"
            :items="DELAY_ITEMS"
            display="value"
            object-property="value"
            :label="$t('devices.tabs.calls.delay')"
            @input="onInputDelay"
          ></b-select-label>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12" class="mt-3" v-if="intercom.settings.is_proxy_call">
          <intercom-range-list
            :title="$t('devices.tabs.calls.coord_schema')"
            :proxy-errors="proxyErrors"
            :is-submit="isSubmit"
          />
        </b-col>
      </b-form-row>
    </div>

    <b-form-row>
      <b-col md="12" v-if="isShowConciergeNumbers" class="mt-4">
        <b-input-hint-label
          id="input-intercom-modal_concierge_numbers"
          name="intercom-concierge-numbers"
          :value="intercom.settings.concierge_numbers"
          :placeholder="$t('devices.tabs.calls.concierge_numbers.title')"
          popover-text="i"
          :popover-hint="$t('devices.tabs.calls.concierge_numbers.hint')"
          hint-id="concierge"
          class="flex-grow-1"
          @update:value="
            (value) => (intercom.settings.concierge_numbers = value)
          "
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import BError from '@/components/base/BError';
import IntercomRangeList from '@/components/modals/intercom/IntercomRangeList.vue';
import BInputHintLabel from '@/components/base/BInputHintLabel';
import { BFormRadio } from 'bootstrap-vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

const CT_VALUES = {
  0: { type: null },
  2: { type: 'both' },
  3: { type: 'sip_then_rs', delay: 10 },
  4: { type: 'sip_then_rs', delay: 20 },
  5: { type: 'sip_then_rs', delay: 30 },
  6: { type: 'sip_then_rs', delay: 60 },
  7: { type: 'sip_then_rs', delay: 120 },
  8: { type: 'rs_then_sip', delay: 10 },
  9: { type: 'rs_then_sip', delay: 20 },
  10: { type: 'rs_then_sip', delay: 30 },
  11: { type: 'rs_then_sip', delay: 60 },
  12: { type: 'rs_then_sip', delay: 120 },
};

const CALL_MODE_TYPES = {
  analog: 'analog',
  proxy: 'proxy',
  regular: 'regular',
};

export default {
  name: 'IntercomCallsTab',
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    validateState: {
      type: Function,
    },
    actualServers: {
      type: Array,
      default: [],
    },
    isRequiredSelectSipServer: {
      type: Boolean,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    proxyErrors: {},
    sipErrors: {},
  },
  components: {
    BFormRadio,
    BError,
    BInputHintLabel,
    IntercomRangeList,
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel', 'callMode']),
    ...mapGetters('intercom', [
      'getIntercom',
      'getIntercomModel',
      'getCallMode',
    ]),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    callMode: {
      get() {
        return this.getCallMode;
      },
      set(value) {
        this.setCallMode(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    isShowConciergeNumbers() {
      return (
        this.intercomModel?.type == 'call_panel' &&
        !this.intercomModel?.tags.includes('no_concierge_numbers')
      );
    },
    isShowAnalog() {
      return this.intercomModel?.tags.includes('is_analog');
    },
    isShowRelative() {
      return !this.intercomModel?.tags.includes('no_relative');
    },
    isShowProxyCall() {
      return this.intercomModel?.tags.includes('proxy_call');
    },
    CALL_MODE_TYPES: () => CALL_MODE_TYPES,
    DELAY_ITEMS() {
      return [
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 60 },
        { value: 120 },
      ];
    },
    TYPE_ITEMS() {
      if (
        this.coordSchemaAvailable &&
        this.intercomModel?.rabbit_queue === 'panel_queue'
      ) {
        return [
          {
            title: this.$t('intercom.call_type.both'),
            value: 'both',
          },
        ];
      }

      return [
        {
          title: this.$t('intercom.call_type.both'),
          value: 'both',
        },
        {
          title: this.$t('intercom.call_type.sip_then_rs'),
          value: 'sip_then_rs',
        },
        {
          title: this.$t('intercom.call_type.rs_then_sip'),
          value: 'rs_then_sip',
        },
      ];
    },
    selectedOptions() {
      return CT_VALUES[Number(this.intercom.settings.analog_call_type || 0)];
    },
    coordSchemaAvailable() {
      return this.intercom.model.name?.includes('RV-3438');
    },
  },
  mounted() {
    if (this.intercom?.settings?.is_analog) {
      this.callMode = CALL_MODE_TYPES.analog;
    } else if (this.intercom?.settings?.is_proxy_call) {
      this.callMode = CALL_MODE_TYPES.proxy;
    } else {
      this.callMode = CALL_MODE_TYPES.regular;
    }
    if (this.intercom.settings.analog_call_type == null) {
      this.inputMode();
    }
  },
  methods: {
    ...mapMutations('intercom', [
      'setMode',
      'parseProxySchema',
      'setIntercom',
      'setCallMode',
    ]),
    intercomSipServer(sip_server) {
      return `${sip_server?.name || ''}`;
    },
    onInputType(type) {
      if (type === 'both') {
        this.intercom.settings.analog_call_type = 2;
      } else {
        const [ctValue] = Object.entries(CT_VALUES).find(
          ([key, value]) =>
            value.type === type &&
            value.delay === (this.selectedOptions.delay || 10),
        );
        this.intercom.settings.analog_call_type = Number(ctValue);
      }
    },
    onInputDelay(delay) {
      const [ctValue] = Object.entries(CT_VALUES).find(
        ([key, value]) =>
          value.type === this.selectedOptions.type && value.delay === delay,
      );
      this.intercom.settings.analog_call_type = Number(ctValue);
    },
    inputMode() {
      this.setMode(this.callMode);
    },
    defineCallMode() {
      if (this.intercom?.settings?.is_analog) {
        this.callMode = CALL_MODE_TYPES.analog;
      } else if (this.intercom?.settings?.is_proxy_call) {
        this.callMode = CALL_MODE_TYPES.proxy;
      } else {
        this.callMode = CALL_MODE_TYPES.regular;
      }
      this.inputMode();
    },
  },
};
</script>

<style lang="scss">
.calls-tab {
  padding: 0 30px 30px 30px;
}
</style>
