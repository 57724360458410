<script>
import { Line } from 'vue-chartjs';

export default {
  name: 'DevicesHeartbeatChart',
  extends: Line,
  props: {
    chartData: Object,
    unit: String,
  },
  data() {
    return {
      osColors: {
        Android: 'rgba(255, 77, 77, 1)',
        'Android Tablet': 'rgba(248, 157, 43, 1)',
        Ios: 'rgba(3, 169, 244, 1)',
        default: 'rgba(90, 183, 92, 1)',
        other: 'rgba(149, 161, 244, 1)',
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: this.unit
            },
            ticks: {
              callback: this.formatXAxesTick
            }
          }],
          yAxes: [{
            min: 0
          }]
        },
        tooltips: {
          callbacks: {
            title: this.formatTooltip
          }
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.modifiedChartData(), this.options);
  },
  methods: {
    modifiedChartData() {
      const lines = [];

      Object.entries(this.chartData.datasets).forEach((element) => {
        lines.push({
          label: element[1].label,
          data: element[1].data,
          borderColor: this.lineOsColor(element[1].label),
          fill: false,
          spanGaps: true
        })
      });

      return {
        datasets: lines.sort((a, b) => a.label.localeCompare(b.label))
      }
    },
    lineOsColor(label) {
      return this.osColors[label] || this.osColors['default']
    },
    formatXAxesTick(value) {
      return this.formatDate(value);
    },
    formatTooltip(tooltipItem) {
      return this.formatDate(tooltipItem[0].label);
    },
    formatDate(value) {
      let format = {
        month: 'long',
        day: 'numeric'
      }
      if (this.unit == 'month') {
        format = {
          year: 'numeric',
          month: 'long',
        }
      }
      let title = new Date(value).toLocaleDateString('ru', format);

      return title;
    },
  },
};
</script>
